import { template as template_84cff577ab3d462eaf2b12332a181bc5 } from "@ember/template-compiler";
const WelcomeHeader = template_84cff577ab3d462eaf2b12332a181bc5(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
