import { template as template_014ad4cf962a46c8871403286fc11a1f } from "@ember/template-compiler";
const FKControlMenuContainer = template_014ad4cf962a46c8871403286fc11a1f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
