import { template as template_9584d6be7fa84b01a9d06487e496950b } from "@ember/template-compiler";
const FKLabel = template_9584d6be7fa84b01a9d06487e496950b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
