import { template as template_417b327f05cf4f66becb5ad0de3b7bb6 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_417b327f05cf4f66becb5ad0de3b7bb6(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
