import { template as template_4a441ede520a4fbfad2d31dc9d40842d } from "@ember/template-compiler";
const SidebarSectionMessage = template_4a441ede520a4fbfad2d31dc9d40842d(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
