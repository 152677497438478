import { template as template_35daafce8c2f45ca93e978854b44c0bb } from "@ember/template-compiler";
const FKText = template_35daafce8c2f45ca93e978854b44c0bb(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
