import { template as template_efc86c1182d54711acbc749de1803488 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_efc86c1182d54711acbc749de1803488(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
